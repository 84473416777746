import React from 'react';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ILink } from 'utils/types';
import { Tagline } from 'components/tagline/Tagline';
import { IconButton } from 'components/button/IconButton';
import { BackgroundSection } from 'components/background/BackgroundSection';
import { Fade } from 'components/animations/Fade';
import { Button } from 'components/button/Button';

import s from './FeaturedArticles.module.scss';

type Props = {
  index?: number;
  tagline?: string;
  title?: string;
  text?: React.ReactNode;
  buttonText?: React.ReactNode;
  link?: ILink;
  isSlice?: boolean;
  background?: boolean;
  design?: 'default' | 'simple';
  children: React.ReactNode;
};

export const FeaturedArticles = ({
  index,
  title,
  tagline,
  text,
  buttonText,
  link,
  isSlice,
  design = 'default',
  children,
}: Props) => {
  const { i18n } = useLingui();
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);

  const content = (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div className={s.articles__layout}>
          {title && (
            <div className={s.articles__row}>
              <div className={s.articles__header}>
                {tagline && (
                  <Fade isVisible={isVisible}>
                    <Tagline text={tagline} index={index} />
                  </Fade>
                )}
                <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0}>
                  <h2 className={s.articles__title}>{title}</h2>
                </Fade>
                {text && (
                  <Fade isVisible={isVisible} delay={baseDelay}>
                    <div className={s.articles__text}>{text}</div>
                  </Fade>
                )}
                {link && (
                  <Fade isVisible={isVisible} delay={baseDelay + 0.15}>
                    <div className={s.articles__button}>
                      {design === 'default' && (
                        <IconButton link={link} color="white">
                          {buttonText || i18n._(t`All articles`)}
                        </IconButton>
                      )}
                      {design === 'simple' && (
                        <Button link={link} color="brand">
                          {buttonText || i18n._(t`All articles`)}
                        </Button>
                      )}
                    </div>
                  </Fade>
                )}
              </div>
            </div>
          )}
          <div className={s.articles__row}>
            <div className={s.articles__col}>
              <div className={s.articles__list}>
                {React.Children.map(children, (component: JSX.Element, i) =>
                  // return max 3 children
                  i < 3 ? React.cloneElement(component, { index: i, isVisible }) : null
                )}
              </div>
            </div>
          </div>
          {link && design === 'simple' && (
            <Fade isVisible={isVisible} delay={baseDelay + 0.15}>
              <div className={s.articles__footer}>
                {design === 'simple' && (
                  <Button link={link} color="brand">
                    {buttonText || i18n._(t`All articles`)}
                  </Button>
                )}
              </div>
            </Fade>
          )}
        </div>
      )}
    </VisibilitySensor>
  );

  const container = (
    <div className={s.articles__segment}>
      <div className={s.articles__container}>{content}</div>
    </div>
  );

  return (
    <BackgroundSection>
      <div className={classnames(s.articles, s[design])}>{isSlice ? container : content}</div>
    </BackgroundSection>
  );
};
